var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.has_baggage_ancillaries
    ? _c("div", { staticClass: "baggage" }, [
        _c("div", { staticClass: "headline" }, [
          _vm._v(_vm._s(_vm._f("locale")("Bagage:")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "passengers" },
          _vm._l(_vm.baggage_ancillaries, function(bag_itinerary) {
            return _c("div", { staticClass: "passenger" }, [
              _c("div", { staticClass: "passenger_number" }, [
                _vm._v(
                  _vm._s(
                    _vm.translatePax(
                      bag_itinerary,
                      _vm.itinerary.attributes.passenger_type
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "leg-container",
                  staticStyle: { float: "left" }
                },
                _vm._l(bag_itinerary, function(bag_flight) {
                  return _c(
                    "div",
                    {
                      staticClass: "leg",
                      style: {
                        display: "inline-block",
                        width: _vm.cabinBagWidth(bag_flight.ancillaries) + "px"
                      }
                    },
                    [
                      _vm._l(bag_flight.ancillaries, function(ancillary) {
                        return [
                          ["BAG1", "CABIN"].includes(ancillary.key)
                            ? _c(
                                "div",
                                {
                                  staticClass: "ancillary",
                                  staticStyle: { clear: "both" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ancillary.selected,
                                        expression: "ancillary.selected"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: _vm.fetch_ancillary_id(ancillary),
                                      id: _vm.fetch_ancillary_id(ancillary),
                                      value: "1",
                                      "data-price": ancillary.price,
                                      "data-price-pax": ancillary.price_per_pax,
                                      "data-ancillary-type": ancillary.type
                                    },
                                    domProps: {
                                      checked: Array.isArray(ancillary.selected)
                                        ? _vm._i(ancillary.selected, "1") > -1
                                        : ancillary.selected
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a = ancillary.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "1",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  ancillary,
                                                  "selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  ancillary,
                                                  "selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(ancillary, "selected", $$c)
                                          }
                                        },
                                        function($event) {
                                          return _vm.$emit("change")
                                        }
                                      ]
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: _vm.fetch_ancillary_id(ancillary)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translateBag(
                                            bag_flight,
                                            ancillary
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }