import { render, staticRenderFns } from "./step_1_body.vue?vue&type=template&id=8ba2c108&scoped=true&"
import script from "./step_1_body.vue?vue&type=script&lang=js&"
export * from "./step_1_body.vue?vue&type=script&lang=js&"
import style0 from "./step_1_body.vue?vue&type=style&index=0&id=8ba2c108&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ba2c108",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/deploy/beta-web-new/web_head/releases/20241011101228/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ba2c108')) {
      api.createRecord('8ba2c108', component.options)
    } else {
      api.reload('8ba2c108', component.options)
    }
    module.hot.accept("./step_1_body.vue?vue&type=template&id=8ba2c108&scoped=true&", function () {
      api.rerender('8ba2c108', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/app/desktop/step_1_body.vue"
export default component.exports